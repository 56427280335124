import React from 'react';

export interface ChangeCardProps {
    date: string;
    description: string;
    icon?: React.ReactNode;
}

const ChangeCard: React.FC<ChangeCardProps> = ({date, description, icon}) => {
    return (
        <div className="change-card">
            {icon && (
                <div className="change-card-icon">
                    {icon}
                </div>
            )}
            <div className="change-card-content">
                <h4>{date}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ChangeCard;
