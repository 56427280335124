import React from 'react';
import config from "../utils/config";

const SwaggerPage: React.FC = () => {
    return (
        <div>
            <h1>Swagger UI</h1>
            <iframe
                src={`${config.apiUrl}/swagger/`}
                style={{width: '100%', height: '100vh', border: 'none'}}
                title="Swagger UI"
            ></iframe>
        </div>
    );
};

export default SwaggerPage;
