import axiosInstance from "../service/axiosConfig";
import {Token} from "./authContext";

export const groupCheckAccess = async (pagePath: string | null, user: Token | null): Promise<boolean> => {
    if (!user || !Array.isArray(user.group)) {
        return false;
    }

    try {
        const response = await axiosInstance.get(`/api/page-access/?page_name=${pagePath}`);
        const pageAccess = response.data[0];

        if (pageAccess && pageAccess.groups.length > 0) {
            return pageAccess.groups.some((groupAccess: any) =>
                user.group.includes(groupAccess.name)
            );
        }
        return false;
    } catch (error) {
        console.error("Failed to check access", error);
        return false;
    }
};

