import React, {useEffect} from 'react';
import InfoTemplate from '../templates/info.template';

const InfoPage: React.FC = () => {

    useEffect(() => {
        document.title = 'ТН-Портал | Информация';
    }, []);

    return (
        <InfoTemplate/>
    );
};

export default InfoPage;
