import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../utils/wrapper/authContext";
import { useNavigate } from "react-router-dom";
import NavList from "../../molecules/header/navList.header";
import UserInfo from "./userInfo.header";
import LogoutConfirmation from "../../molecules/header/logoutConfirmation.header";

const Header: React.FC = () => {
    const { logout, user, fetchUser } = useAuth();
    const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(navigate);
    };

    const toggleDropdown = (event: React.MouseEvent) => {
        event.stopPropagation();
        setDropdownOpen(!isDropdownOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                closeDropdown();
            }
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                closeMenu();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Обновляем данные пользователя при монтировании компонента
        fetchUser();
    }, [fetchUser]);

    return (
        <div className="header-container">
        <header className="header">
            <nav className="nav">
                <div className="menu-icon" onClick={toggleMenu} ref={menuRef}>
                    &#9776;
                </div>
                <NavList isMenuOpen={isMenuOpen} />
                {isDropdownOpen ? (
                    <LogoutConfirmation
                        onConfirm={handleLogout}
                        onCancel={closeDropdown}
                    />
                ) : (
                    <div ref={dropdownRef}>
                        <UserInfo
                            userName={user?.first_name ?? null}
                            lastName={user?.last_name ?? null}
                            userGroup={user?.group ?? null}
                            avatarURL={user?.avatar ?? null}
                            isDropdownOpen={isDropdownOpen}
                            toggleDropdown={toggleDropdown}
                            handleLogout={handleLogout}
                        />
                    </div>
                )}
            </nav>
        </header>
        </div>
    );
};

export default Header;
