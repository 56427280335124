import React from 'react';
import AccessLevelCard from "../../molecules/profile/accessLevelCard.profile";


export interface AccessLevelCardListProps {
    levels: Array<'AUP' | 'ADMIN' | 'TECH'>;
}

const AccessLevelCardList: React.FC<AccessLevelCardListProps> = ({levels}) => {
    return (
        <div className="access-level-card-list">
            {levels.map(level => (
                <AccessLevelCard
                    key={level}
                    level={level}
                />
            ))}
        </div>
    );
};

export default AccessLevelCardList;
