import React from 'react';
import '../../styles/home.css';
import {NewsItem} from '../../utils/service/home.service';
import {useNavigate} from 'react-router-dom';

const NewsCard: React.FC<NewsItem> = ({id, date, topic, short, picture, thumbnail}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/news/${id}`);
    };

    return (
        <div className="news-card" onClick={handleClick}>
            {picture && (
                <div className="news-card-image-container">
                    <img src={thumbnail} alt={topic} className="news-card-image"/>
                </div>
            )}
            <div className="news-card-content">
                <h3 className="news-card-title">{topic}</h3>
                <p className="news-card-date">{date}</p>
                <p className="news-card-short">{short}</p>
            </div>
        </div>
    );
};

export default NewsCard;
