import React, { useEffect, useRef, useState } from 'react';
import { NewsItem } from '../../utils/service/home.service';
import NewsCard from './newsCardMolecule.home'; // Ваш компонент NewsCard

const NewsBlock: React.FC<{ news: NewsItem[] }> = ({ news }) => {
    const newsContainerRef = useRef<HTMLDivElement>(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const checkScrollable = () => {
            if (newsContainerRef.current) {
                const { scrollHeight, clientHeight } = newsContainerRef.current;
                setIsScrollable(scrollHeight > clientHeight);
            }
        };

        checkScrollable();
        window.addEventListener('resize', checkScrollable);

        return () => {
            window.removeEventListener('resize', checkScrollable);
        };
    }, [news]);

    const scrollUp = () => {
        if (newsContainerRef.current) {
            newsContainerRef.current.scrollBy({
                top: -150,
                behavior: 'smooth',
            });
        }
    };

    const scrollDown = () => {
        if (newsContainerRef.current) {
            newsContainerRef.current.scrollBy({
                top: 150,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div
            className="news-block-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isScrollable && isHovered && (
                <button className="news-scroll-button news-scroll-up" onClick={scrollUp}>
                    ▲
                </button>
            )}
            <div className="news-block" ref={newsContainerRef}>
                {news.map((item) => (
                    <NewsCard
                        key={item.id}
                        id={item.id}
                        date={item.date}
                        topic={item.topic}
                        short={item.short}
                        picture={item.picture}
                        thumbnail={item.thumbnail}
                    />
                ))}
            </div>
            {isScrollable && isHovered && (
                <button className="news-scroll-button news-scroll-down" onClick={scrollDown}>
                    ▼
                </button>
            )}
        </div>
    );
};

export default NewsBlock;
