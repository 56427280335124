import React, {useEffect, useState} from 'react';
import {
    fetchCarouselImages,
    fetchCompanyInfo,
    fetchNews,
    NewsItem,
    CarouselItem,
} from '../utils/service/home.service';
import HomePageTemplate from "../templates/home.template";
import {CompanyInfoProps} from "../molecules/home/companyInfoMolecule.home";

const HomePage: React.FC = () => {
    const [companyInfo, setCompanyInfo] = useState<CompanyInfoProps[]>([]);
    const [news, setNews] = useState<NewsItem[]>([]);
    const [carouselImages, setCarouselImages] = useState<CarouselItem[]>([]);

    useEffect(() => {
        document.title = 'ТН-Портал | Главная страница';
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const carouselImages = await fetchCarouselImages();
                setCarouselImages(carouselImages);

                const companyInfo = await fetchCompanyInfo();
                const companyInfoData: CompanyInfoProps[] = [
                    {
                        name: companyInfo?.name || '',
                        description: companyInfo?.description || '',
                        contactEmail: companyInfo?.contact_email || '',
                        mapUrl: companyInfo?.map || '',
                        phoneNumber: companyInfo?.phone_number || '',
                        logoUrl: companyInfo?.logo || '',
                        address: companyInfo?.address || ''
                    }
                ]
                setCompanyInfo(companyInfoData);

                const news = await fetchNews(10);
                setNews(news);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
    }, []);

    return (
        <HomePageTemplate
            carouselImages={carouselImages}
            companyInfo={companyInfo}
            news={news}
        />
    );
};

export default HomePage;
