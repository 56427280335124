import React from 'react';

interface ButtonProps {
    text: string;
    type?: 'button' | 'submit';
}

const ButtonLogin: React.FC<ButtonProps> = ({text, type = 'submit'}) => {
    return <button type={type} className="login-button">{text}</button>;
};

export default ButtonLogin;