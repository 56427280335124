import React from 'react';
import '../../styles/profile.css'
interface FileInputButtonProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    disabled?: boolean;
}

const FileInputButton: React.FC<FileInputButtonProps> = ({onChange, label, disabled}) => (
    <div className="file-input-button">
        <label className="file-input-button-label"
               style={{pointerEvents: disabled ? 'none' : 'auto', cursor: 'pointer'}}>
            {label}
        </label>
        <input
            type="file"
            onChange={onChange}
            className="file-input"
            disabled={disabled}
        />
    </div>
);

export default FileInputButton;
