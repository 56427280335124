import React, {useState} from "react";
import {Person} from "../utils/service/info.service";

interface PersonFormProps {
    person: Person | null;
    onSave: (person: Person) => void;
    onCancel: () => void;
}

const PersonForm: React.FC<PersonFormProps> = ({person, onSave, onCancel}) => {
    const [formData, setFormData] = useState<Person>(
        person || {
            first_name: '',
            last_name: '',
            age: 0,
            visits: 0,
            progress: 0,
            access: 'full',
        }
    );

    const handleChange = (field: keyof Person, value: string | number) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        onSave(formData);
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h3>{person ? 'Edit Person' : 'Add Person'}</h3>
                <input
                    type="text"
                    value={formData.first_name}
                    onChange={(e) => handleChange('first_name', e.target.value)}
                    placeholder="First Name"
                />
                <input
                    type="text"
                    value={formData.last_name}
                    onChange={(e) => handleChange('last_name', e.target.value)}
                    placeholder="Last Name"
                />
                <input
                    type="number"
                    value={formData.age}
                    onChange={(e) => handleChange('age', parseInt(e.target.value))}
                    placeholder="Age"
                />
                <input
                    type="number"
                    value={formData.visits}
                    onChange={(e) => handleChange('visits', parseInt(e.target.value))}
                    placeholder="Visits"
                />
                <input
                    type="number"
                    value={formData.progress}
                    onChange={(e) => handleChange('progress', parseInt(e.target.value))}
                    placeholder="Progress"
                />
                <select
                    value={formData.access}
                    onChange={(e) => handleChange('access', e.target.value)}
                >
                    <option value="full">Full</option>
                    <option value="first">First</option>
                    <option value="second">Second</option>
                </select>
                <button onClick={handleSubmit}>Save</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default PersonForm;