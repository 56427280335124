import React from "react";
import UserAvatar from "../../atoms/header/userAvatar.header";
import UserDetails from "../../atoms/header/userDetail.header";
import DropdownMenu from "../../atoms/header/dropDownMenu.header";
import config from "../../utils/config";

interface UserInfoProps {
    userName: string | null;
    lastName: string | null;
    userGroup: string | null;
    avatarURL: string | null;
    isDropdownOpen: boolean;
    toggleDropdown: (event: React.MouseEvent) => void;
    handleLogout: () => void;
    className?: string;
}

const UserInfo: React.FC<UserInfoProps> = ({
                                               userName,
                                               lastName,
                                               userGroup,
                                               avatarURL,
                                               isDropdownOpen,
                                               toggleDropdown,
                                               handleLogout,
                                               className = ''
                                           }) => {
    const avatarSrc = avatarURL ? `${config.apiUrl}${avatarURL}?${new Date().getTime()}` : '';

    return (
        <div className={`user-info ${className}`} onClick={toggleDropdown} style={{cursor: 'pointer'}}>
            {userName ? (
                <>
                    <UserAvatar src={avatarSrc} alt={'ava'}/>
                    <UserDetails name={`${userName} ${lastName}`} role={userGroup}/>
                    {isDropdownOpen && <DropdownMenu onLogout={handleLogout}/>}
                </>
            ) : (
                <button className="logout-button" onClick={handleLogout}>Выйти</button>
            )}
        </div>
    );
};

export default UserInfo;
