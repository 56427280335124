import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import LoginPage from './pages/login.page';
import HomePage from './pages/home.page';
import ProfilePage from './pages/profile.page';
import InfoPage from './pages/info.page';
import NotFoundPage from './pages/notFound.page';
import Header from './organisms/header/header';
import Footer from './organisms/footer';
import {AuthProvider, useAuth} from './utils/wrapper/authContext';
import GroupRestrictedRoute from './utils/wrapper/groupRestrictedRoute';
import TokenRefreshWrapper from './utils/wrapper/tokenRefreshWrapper';
import './App.css';
import PrivateRoute from "./utils/wrapper/privateRoute";
import NewsPage from "./pages/news.page";
import SwaggerPage from "./pages/swaggerPage";


const App: React.FC = () => {
    return (
        <Router>
            <AuthProvider>
                <TokenRefreshWrapper>
                    <div className="app">
                        <ConditionalLayout>
                            <Routes>
                                <Route path="/" element={<RootRedirect/>}/>
                                <Route path="/home" element={<PrivateRoute><HomePage/></PrivateRoute>}/>
                                <Route path="/profile" element={<PrivateRoute><ProfilePage/></PrivateRoute>}/>
                                <Route path="/info" element={
                                    <PrivateRoute><GroupRestrictedRoute><InfoPage/></GroupRestrictedRoute></PrivateRoute>
                                }/>
                                <Route path="/news/:id" element={<PrivateRoute><NewsPage/></PrivateRoute>}/>
                                <Route path="/swagger" element={
                                    <PrivateRoute><SwaggerPage/></PrivateRoute>}/>
                                <Route path="*" element={<HandleUnknownPaths/>}/>
                            </Routes>
                        </ConditionalLayout>
                    </div>
                </TokenRefreshWrapper>
            </AuthProvider>
        </Router>
    );
};

// Обрабатывает несуществующие пути в зависимости от состояния авторизации
const HandleUnknownPaths: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <NotFoundPage/> : <Navigate to="/" replace/>;
};

// условно рендерит Header и Footer
const ConditionalLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const location = useLocation();
    const hideHeaderAndFooter = location.pathname === '/' || location.pathname.startsWith('/login');

    return (
        <>
            {!hideHeaderAndFooter && <Header/>}
            <main>{children}</main>
            {!hideHeaderAndFooter && <Footer/>}
        </>
    );
};

const RootRedirect: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <Navigate to="/home" replace/> : <LoginPage/>;
};


export default App;
