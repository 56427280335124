import React from "react";

interface LogoutConfirmationProps {
    onConfirm: () => void;
    onCancel: () => void;
}

const LogoutConfirmation: React.FC<LogoutConfirmationProps> = ({onConfirm, onCancel}) => {
    return (
        <div className="logout-confirmation">
            <p>Выйти?</p>
            <button onClick={onConfirm}>Да</button>
            <button onClick={onCancel}>Нет</button>
        </div>
    );
};

export default LogoutConfirmation;
