import React from 'react';
import '../../styles/header.css';

interface UserDetailsProps {
    name: string;
    role: string | null;
}

const UserDetails: React.FC<UserDetailsProps> = ({name, role}) => (
    <div className="user-details">
        <p className="user-name">{name}</p>
        <p className="user-role">{role}</p>
    </div>
);

export default UserDetails;
