import axiosInstance from './axiosConfig';

export interface NewsItem {
    id?: string;
    date: string;
    topic: string;
    short: string;
    summary?: string;
    picture: string;
    thumbnail:string;
}

export interface CarouselItem {
    id?: string;
    description?: string;
    image: string;
    order_num?: number;
    is_active?: boolean;
}

export interface CompanyResponseProps {
    name: string;
    description: string;
    contact_email: string;
    map: string;
    phone_number: string;
    logo: string;
    address: string;
}

// Метод для получения изображений карусели
export const fetchCarouselImages = async (): Promise<CarouselItem[]> => {
    const response = await axiosInstance.get('/api/carousel/');
    return response.data;
};

// Метод для получения информации о компании
export const fetchCompanyInfo = async (): Promise<CompanyResponseProps> => {
    const response = await axiosInstance.get('/api/company-info/');
    return response.data[0];
};

// Метод для получения списка новостей
export const fetchNews = async (limit: number): Promise<NewsItem[]> => {
    const response = await axiosInstance.get('/api/news/', {
        params: {limit}
    });
    return response.data;
};

// Метод для получения данных конкретной новости по ID
export const fetchNewsById = async (id: string): Promise<NewsItem> => {
    const response = await axiosInstance.get(`/api/news/${id}/`);
    return response.data;
};
