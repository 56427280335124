import React from 'react';
import '../../styles/header.css';
import {FaSignOutAlt} from 'react-icons/fa';


interface DropdownMenuProps {
    onLogout: () => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({onLogout}) => {
    return (
        <div className="dropdown-menu">
            <button className="dropdown-item" onClick={onLogout}>
                <FaSignOutAlt className="dropdown-menu-icon"/>
                <span className="dropdown-menu-text">Выйти</span>
            </button>
        </div>
    );
};

export default DropdownMenu;
