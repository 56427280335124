import React from 'react';
import {FaEnvelope, FaMapMarkerAlt, FaPhone} from 'react-icons/fa';
import '../../styles/home.css';

interface CompanyInfoData {
    data: CompanyInfoProps
}

export interface CompanyInfoProps {
    name: string;
    description: string;
    contactEmail: string;
    mapUrl: string;
    phoneNumber: string;
    logoUrl: string;
    address: string;
}

const CompanyInfo: React.FC<CompanyInfoData> = ({
                                                    data
                                                }) => (
    <div className="company-info">
        <div className="company-info-header">
            <div className="company-info-logo">
                <img src={data.logoUrl} alt='my company'/>
            </div>
            <div className="company-info-title">
                <h2>{data.name}</h2>
                <p className="company-info-description">{data.description}</p>
            </div>
        </div>

        <div className="company-info-content">
            {data.mapUrl && (
                <div className="company-info-map">
                    <iframe
                        src={data.mapUrl}
                        width="100%"
                        height="300"
                        frameBorder="0"
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex={0}
                        title="Компания на карте"
                    ></iframe>
                </div>
            )}
            <div className="company-info-contact-block">
                <div className="company-info-contact">
                    <FaEnvelope size={20} color="#007BFF"/>
                    <a href={`mailto:${data.contactEmail}`} className="company-info-email">{data.contactEmail}</a>
                </div>
                {data.phoneNumber && (
                    <div className="company-info-contact">
                        <FaPhone size={20} color="#007BFF"/>
                        <p className="company-info-phone-text">{data.phoneNumber}</p>
                    </div>
                )}
                {data.address && (
                    <div className="company-info-address">
                        <FaMapMarkerAlt size={20} color="#007BFF"/>
                        <p className="company-info-address-text">{data.address}</p>
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default CompanyInfo;
