import React from 'react';
import Input from "../../atoms/profile/input.profile";
import {Profile} from "../../pages/profile.page";

interface ProfileInfoProps {
    firstName: string;
    lastName: string;
    email: string;
    onChange: (field: keyof Profile, value: string) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ firstName, lastName, email, onChange }) => (
    <div>
        <Input
            label="Имя"
            value={firstName}
            onChange={(e) => onChange('user_first_name', e.target.value)}
        />
        <Input
            label="Фамилия"
            value={lastName}
            onChange={(e) => onChange('user_last_name', e.target.value)}
        />
        <Input
            label="E-mail"
            value={email}
            onChange={(e) => onChange('user_email', e.target.value)}
        />
    </div>
);

export default ProfileInfo;
