import React, {useEffect} from 'react';

interface ToastProps {
    message: string;
    type: 'success' | 'error';
    onClose: () => void;
    duration?: number;
}

const Toast: React.FC<ToastProps> = ({message, type, onClose, duration = 2000}) => {

    useEffect(() => {
        const timer = setTimeout(onClose, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [onClose, duration]);

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: type === 'success' ? '#4CAF50' : '#F44336',
            color: '#fff',
            padding: '16px',
            borderRadius: '4px',
            zIndex: 1000,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center'
        }}>
            {message}
            <button onClick={onClose} style={{
                marginLeft: '16px',
                backgroundColor: 'transparent',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
            }}>
                &times;
            </button>
        </div>
    );
};

export default Toast;
