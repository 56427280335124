import React from 'react';
import '../../styles/login.css'

interface InputFieldProps {
    id: string;
    type: string;
    placeholder?: string;
    error?: string;
    register: any;
    value?: string;
}

const InputField: React.FC<InputFieldProps> = ({id, type, placeholder, register, error, value}) => {
    return (
        <div className="login-input-field">
            <input
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                {...register(id)}
                className={error ? 'input-error' : ''}
            />
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default InputField;
