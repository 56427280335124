import React from 'react';
import TableComponent from '../organisms/info/table.info.cmp';
import '../styles/info.css'
const InfoTemplate: React.FC = () => {
    return (
        <div className='info-container'>
            <TableComponent/>
        </div>
    );
};

export default InfoTemplate;
