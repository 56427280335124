import React from 'react';
import '../styles/footer.css'

const Footer: React.FC = () => {
    return (
        <footer className='footer'>
            <p>&copy; All rights reserved.</p>
        </footer>
    );
};

export default Footer;
