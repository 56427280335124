import React from "react";
import {ColumnDef, flexRender, getCoreRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {Person} from "../../utils/service/info.service";

interface MyTableProps {
    data: Person[];
    columns: ColumnDef<Person>[];
    pageCount: number;
    pageIndex: number;
    pageSize: number;
    setPageIndex: (index: number) => void;
    setPageSize: (size: number) => void;
    sorting: SortingState;
    setSorting: (sorting: any) => void;
}

const MyTable: React.FC<MyTableProps> = ({
                                             data,
                                             columns,
                                             pageCount,
                                             pageIndex,
                                             pageSize,
                                             setPageIndex,
                                             setPageSize,
                                             sorting,
                                             setSorting,
                                         }) => {
    const table = useReactTable({
        columns,
        data,
        pageCount,
        state: {
            pagination: {pageIndex, pageSize},
            sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualSorting: true,
        onPaginationChange: (updater: any) => {
            const newPagination = updater({pageIndex, pageSize});
            setPageIndex(Math.floor(newPagination.pageIndex));
            setPageSize(Math.floor(newPagination.pageSize));
        },
        onSortingChange: setSorting,
    });

    const renderPagination = () => {
        const pages = [];
        const visiblePages = 5;

        const startPage = Math.max(0, pageIndex - Math.floor(visiblePages / 2));
        const endPage = Math.min(pageCount, startPage + visiblePages);

        // Добавление первой страницы и "..."
        if (startPage > 0) {
            pages.push(
                <button key={0} onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
                    1
                </button>
            );
            if (startPage > 1) {
                pages.push(<span key="start-ellipsis">...</span>);
            }
        }

        // Добавление видимых страниц
        for (let i = startPage; i < endPage; i++) {
            pages.push(
                <button key={i} onClick={() => setPageIndex(i)} disabled={i === pageIndex}>
                    {i + 1}
                </button>
            );
        }

        // Добавление последней страницы и "..."
        if (endPage < pageCount) {
            if (endPage < pageCount - 1) {
                pages.push(<span key="end-ellipsis">...</span>);
            }
            pages.push(
                <button key={pageCount - 1} onClick={() => setPageIndex(pageCount - 1)}
                        disabled={pageIndex === pageCount - 1}>
                    {pageCount}
                </button>
            );
        }

        return pages;
    };

    return (
        <div className="table-wrapper">
            <div className="table-scrollable">
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{width: `${header.column.getSize()}px`}}
                                >
                                    <div>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
                    {'<<'}
                </button>
                <button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
                    {'<'}
                </button>
                {renderPagination()}
                <button onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
                    {'>'}
                </button>
                <button onClick={() => setPageIndex(pageCount - 1)} disabled={pageIndex === pageCount - 1}>
                    {'>>'}
                </button>
            </div>
        </div>
    );
};

export default MyTable;