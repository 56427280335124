import React from 'react';

const NotFoundPage: React.FC = () => {
    return (
        <div style={{maxWidth: '1200px', margin: 'auto', width: '100%'}}>
            <h1>404</h1>
            <p>Страница не найдена</p>
        </div>
    );
};

export default NotFoundPage;
