import React from 'react';

interface CarouselIndicatorsMoleculeProps {
    count: number;
    currentIndex: number;
    onIndicatorClick: (index: number) => void;
}

const CarouselIndicatorsMolecule: React.FC<CarouselIndicatorsMoleculeProps> = ({ count, currentIndex, onIndicatorClick }) => {
    return (
        <div className="carousel-indicators">
            {Array.from({ length: count }).map((_, index) => (
                <span
                    key={index}
                    className={`indicator ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => onIndicatorClick(index)}
                    aria-label={`Go to slide ${index + 1}`}
                ></span>
            ))}
        </div>
    );
};

export default CarouselIndicatorsMolecule;
