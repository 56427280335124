import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import {useAuth} from "./authContext";


interface Token {
    exp: number; // Время истечения токена в формате Unix timestamp
}

const TokenRefreshWrapper: FC<{ children: React.ReactNode }> = ({children}) => {
    const {refreshAccessToken, logout, accessToken} = useAuth();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAndRefreshToken = async () => {
            if (!accessToken) {
                setLoading(false);
                return;
            }

            const decodedToken = jwtDecode<Token>(accessToken);
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp - currentTime < 5 * 60) {
                try {
                    await refreshAccessToken();
                } catch (error) {
                    console.error('Failed to refresh token. Logging out...', error);
                    logout(navigate);
                }
            }

            setLoading(false);
        };

        checkAndRefreshToken();
    }, [accessToken, refreshAccessToken, logout, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return <>{children}</>;
};

export default TokenRefreshWrapper;
