import React from 'react';
import ProfileCard from '../organisms/profile/profileCard.profile';
import ChangeCard from '../molecules/profile/changeCard.profile';
import Container from '../atoms/profile/container.profile';
import {Profile} from "../pages/profile.page";
import '../styles/profile.css';
import AccessLevelCardList from "../organisms/profile/accessLevelCardList.profile";

// Пример данных для блока изменений
const recentChanges = [
    {date: '10 августа 2024', description: 'Изменен аватар', icon: '🖼️'},
    {date: '8 августа 2024', description: 'Обновлены данные профиля', icon: '📝'},
    {date: '6 августа 2024', description: 'Добавлен новый уровень доступа', icon: '🔑'},
    {date: '1 августа 2024', description: 'Обновлены данные профиля', icon: '📝'},
    {date: '1 августа 2024', description: 'Обновлены данные профиля', icon: '📝'},
    {date: '1 августа 2024', description: 'Обновлены данные профиля', icon: '📝'},
    {date: '25 июля 2024', description: 'Изменен аватар', icon: '🖼️'},
];

export interface ProfileTemplateProps {
    avatarSrc: string;
    firstName: string;
    lastName: string;
    email: string;
    userGroups: Array<'AUP' | 'ADMIN' | 'TECH'>;
    onAvatarChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onProfileChange: (field: keyof Profile, value: string) => void;
    onDeleteAvatar: () => void;
    onSaveChanges: () => void;
}

const ProfileTemplate: React.FC<ProfileTemplateProps> = ({
                                                             avatarSrc,
                                                             firstName,
                                                             lastName,
                                                             email,
                                                             userGroups,
                                                             onAvatarChange,
                                                             onProfileChange,
                                                             onDeleteAvatar,
                                                             onSaveChanges,
                                                         }) => {

    return (
        <React.Fragment>
            <ProfileCard
                avatarSrc={avatarSrc}
                firstName={firstName}
                lastName={lastName}
                email={email}
                onAvatarChange={onAvatarChange}
                onProfileChange={onProfileChange}
                onDeleteAvatar={onDeleteAvatar}
                onSaveChanges={onSaveChanges}
            />
            <div className="rightColumn">
                <Container>
                    <h2>Уровень доступа</h2>
                    <AccessLevelCardList levels={userGroups}/>
                </Container>
                <Container>
                    <h2>Последние изменения</h2>
                    <div className="recent-changes-container">
                        {recentChanges.map((change, index) => (
                            <ChangeCard
                                key={index}
                                date={change.date}
                                description={change.description}
                                icon={change.icon}
                            />
                        ))}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProfileTemplate;
