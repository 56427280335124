import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {NewsItem, fetchNewsById} from '../utils/service/home.service';
import {useEffect, useState} from 'react';
import '../styles/news.css';

const NewsPage: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [newsItem, setNewsItem] = useState<NewsItem | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNewsData = async () => {
            if (id) {
                try {
                    const data = await fetchNewsById(id);
                    setNewsItem(data);
                } catch (error) {
                    console.error('Error fetching news data:', error);
                }
            }
        };
        fetchNewsData();
    }, [id]);

    return (
        <div className="news-page">
            <button className="back-button" onClick={() => navigate('/home')}>
                ← Вернуться на главную
            </button>
            {newsItem ? (
                <div className="news-content">
                    <h1 className="news-title">{newsItem.topic}</h1>
                    <p className="news-date">{newsItem.date}</p>
                    <img className="news-image" src={newsItem.picture} alt={newsItem.topic}/>
                    <p className="news-text">{newsItem.summary}</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default NewsPage;
