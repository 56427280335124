import React, {useEffect, useMemo, useRef, useState} from 'react';
import '../../styles/info.css';
import {
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import {fetchData, Person, deletePerson, updatePerson} from '../../utils/service/info.service';
import PersonForm from "../../molecules/personForm.info";
import MyTable from "./myTable.info";

const TableComponent: React.FC = () => {
    const [data, setData] = useState<Person[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [filter, setFilter] = useState<{ [key: string]: string }>({});
    const [editingPerson, setEditingPerson] = useState<Person | null>(null);

    const filterRefs = useRef<{ [key: string]: string }>({});
    const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

    const handleFilterChange = (field: string, value: string) => {
        filterRefs.current[field] = value;
        setFilter({...filterRefs.current});

        // Возвращаем фокус на поле после изменения фильтра
        setTimeout(() => {
            if (inputRefs.current[field]) {
                inputRefs.current[field]?.focus();
            }
        }, 0);
    };


    // Обновляем фильтр при нажатии Enter
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.key === 'Enter') {
            setFilter({...filterRefs.current});
        }
    };

    const resetFilters = () => {
        setFilter({});
        filterRefs.current = {};
    };

    const handleSort = (columnId: string) => {
        setSorting((prev) => {
            const currentSort = prev.find(sort => sort.id === columnId);
            if (currentSort) {
                return currentSort.desc
                    ? prev.filter(sort => sort.id !== columnId)
                    : prev.map(sort =>
                        sort.id === columnId ? {...sort, desc: true} : sort
                    );
            }
            return [{id: columnId, desc: false}];
        });
    };

    const handleEdit = (person: Person) => {
        setEditingPerson(person);
    };

    const handleDelete = async (id: any) => {
        try {
            await deletePerson(id);
            fetchDataAsync();
        } catch (error) {
            console.error('Failed to delete person:', error);
        }
    };

    const handleSave = async (person: Person) => {
        try {
            await updatePerson(person);
            setEditingPerson(null);
            fetchDataAsync();
        } catch (error) {
            console.error('Failed to save person:', error);
        }
    };

    const handleAdd = () => {
        setEditingPerson({
            first_name: '',
            last_name: '',
            age: 0,
            visits: 0,
            progress: 0,
            access: 'full',
        });
    };

    const columns = useMemo<ColumnDef<Person>[]>(
        () => [
            {
                accessorKey: 'first_name',
                header: () => (
                    <div>
                    <span onClick={() => handleSort('first_name')}>
                        First Name {sorting.find(sort => sort.id === 'first_name') ? (sorting.find(sort => sort.id === 'first_name')?.desc ? '↓' : '↑') : '⇅'}
                    </span>
                        <input
                            type="text"
                            placeholder="Filter"
                            style={{width: '100px'}}
                            defaultValue={filter['first_name'] || ''}
                            onChange={(e) => handleFilterChange('first_name', e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={(el) => inputRefs.current['first_name'] = el}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'last_name',
                header: () => (
                    <div>
                        <span onClick={() => handleSort('last_name')}>
                            Last Name {sorting.find(sort => sort.id === 'last_name') ? (sorting.find(sort => sort.id === 'last_name')?.desc ? '↓' : '↑') : '⇅'}
                        </span>
                        <input
                            type="text"
                            placeholder="Filter"
                            style={{width: '100px'}}
                            defaultValue={filter['last_name'] || ''}
                            onChange={(e) => handleFilterChange('last_name', e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={(el) => inputRefs.current['last_name'] = el}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'age',
                header: () => (
                    <div>
                        <span onClick={() => handleSort('age')}>
                            Age {sorting.find(sort => sort.id === 'age') ? (sorting.find(sort => sort.id === 'age')?.desc ? '↓' : '↑') : '⇅'}
                        </span>
                        <input
                            type="number"
                            placeholder="Filter"
                            style={{width: '100px'}}
                            defaultValue={filter['age'] || ''}
                            onChange={(e) => handleFilterChange('age', e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={(el) => inputRefs.current['age'] = el}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'visits',
                header: () => (
                    <div>
                        <span onClick={() => handleSort('visits')}>
                            Visits {sorting.find(sort => sort.id === 'visits') ? (sorting.find(sort => sort.id === 'visits')?.desc ? '↓' : '↑') : '⇅'}
                        </span>
                        <input
                            type="number"
                            placeholder="Filter"
                            style={{width: '100px'}}
                            defaultValue={filter['visits'] || ''}
                            onChange={(e) => handleFilterChange('visits', e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={(el) => inputRefs.current['visits'] = el}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'access',
                header: () => (
                    <div>
                        <span onClick={() => handleSort('access')}>
                            Access LVL {sorting.find(sort => sort.id === 'access') ? (sorting.find(sort => sort.id === 'access')?.desc ? '↓' : '↑') : '⇅'}
                        </span>
                        <select
                            style={{width: '100px'}}
                            value={filter['access'] || ''}
                            onChange={(e) => handleFilterChange('access', e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="full">Full</option>
                            <option value="first">First</option>
                            <option value="second">Second</option>
                        </select>
                    </div>
                ),
            },
            {
                accessorKey: 'progress',
                header: () => (
                    <div>
                        <span onClick={() => handleSort('progress')}>
                            Progress {sorting.find(sort => sort.id === 'progress') ? (sorting.find(sort => sort.id === 'progress')?.desc ? '↓' : '↑') : '⇅'}
                        </span>
                        <input
                            type="number"
                            placeholder="Filter"
                            style={{width: '100px'}}
                            defaultValue={filter['progress'] || ''}
                            onChange={(e) => handleFilterChange('progress', e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={(el) => inputRefs.current['progress'] = el}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'actions',
                header: 'Actions',
                cell: ({row}) => (
                    <div>
                        <button onClick={() => handleEdit(row.original)}>Edit</button>
                        <button onClick={() => handleDelete(row.original.id)}>Delete</button>
                    </div>
                ),
            },
        ],
        [filter, sorting]
    );

    const fetchDataAsync = async () => {
        try {
            const response = await fetchData(
                pageIndex + 1,
                pageSize,
                sorting[0]?.id ?? '',
                sorting[0]?.desc ? 'desc' : 'asc',
                filter
            );
            setData(response.data);
            setPageCount(Math.floor(response.totalPages));
        } catch (error: any) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchDataAsync();
    }, [pageIndex, pageSize, sorting, filter]);

    return (
        <div className="table-container">
            <button onClick={resetFilters} disabled={Object.keys(filter).length === 0}>
                Сбросить фильтр
            </button>
            <button onClick={handleAdd}>Добавить запись</button>
            <MyTable
                {...{
                    data,
                    columns,
                    pageCount,
                    pageIndex,
                    setPageIndex,
                    pageSize,
                    setPageSize,
                    setSorting,
                    sorting,
                }}
            />
            {editingPerson && (
                <PersonForm
                    person={editingPerson}
                    onSave={handleSave}
                    onCancel={() => setEditingPerson(null)}
                />
            )}
        </div>
    );
};

export default TableComponent;
