import React, {ChangeEvent} from 'react';

interface InputProps {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({label, value, onChange}) => (
    <div className="input-wrapper">
        <label className="input-label">{label}</label>
        <input
            type="text"
            value={value}
            onChange={onChange}
            className="input-field"
        />
    </div>
);

export default Input;
