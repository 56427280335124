import React, {useEffect} from 'react';
import Avatar from '../../atoms/profile/avatar.profile';
import ProfileInfo from '../../molecules/profile/profileInfo.profile';
import Button from '../../atoms/profile/button.profile';
import {Profile} from "../../pages/profile.page";
import config from "../../utils/config";
import FileInputButton from "../../atoms/profile/fileInputButton.profile";

interface ProfileCardProps {
    avatarSrc: string;
    firstName: string;
    lastName: string;
    email: string;
    onAvatarChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onProfileChange: (field: keyof Profile, value: string) => void;
    onDeleteAvatar: () => void;
    onSaveChanges: () => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
                                                     avatarSrc, firstName, lastName, email,
                                                     onAvatarChange, onProfileChange, onDeleteAvatar, onSaveChanges
                                                 }) => {

    return (
        <div className="profile-card">
            <div className="avatar-container">
                {avatarSrc ? (
                    <Avatar
                        src={`${config.apiUrl}${avatarSrc}`}
                        alt={`${firstName} ${lastName}`}
                    />
                ) : (
                    <div className="no-avatar">Нет аватара</div>
                )}
            </div>
            <FileInputButton onChange={onAvatarChange} label="Изменить аватар"/>
            {/*<Button onClick={onDeleteAvatar} label="Удалить аватар" disabled={!avatarSrc}/>*/}
            <br/>
            <ProfileInfo
                firstName={firstName}
                lastName={lastName}
                email={email}
                onChange={onProfileChange}
            />
            <Button onClick={onSaveChanges} label="Сохранить изменения"/>
            <br/>
            <br/>
        </div>
    )
};

export default ProfileCard;
