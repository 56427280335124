import React from 'react';
import NavLink from "../../atoms/header/navlink.header";
import '../../styles/header.css';
import {useLocation} from "react-router-dom";

interface NavListProps {
    isMenuOpen: boolean;
}

const NavList: React.FC<NavListProps> = ({isMenuOpen}) => {
    const location = useLocation();
    const isProfileRoute = location.pathname === '/info';

    const className = `nav-list ${isMenuOpen ? 'open' : ''} ${isProfileRoute ? 'nav-list-info' : ''}`;

    return (
        <ul className={className}>
            <li><NavLink to="/home" label="Главная"/></li>
            <li><NavLink to="/profile" label="Кабинет"/></li>
            <li><NavLink to="/info" label="Информация"/></li>
        </ul>
    );
};

export default NavList;
