import React from 'react';

export interface AccessLevelCardProps {
    level: 'AUP' | 'ADMIN' | 'TECH';
}

const accessLevelData = {
    'AUP': {
        title: 'Административный персонал',
        description: 'Доступ к основным функциям системы и информационной таблице.',
        icon: '👤',
    },
    'ADMIN': {
        title: 'Администратор',
        description: 'Полный доступ к управлению системой.',
        icon: '🛠️',
    },
    'TECH': {
        title: 'Технический специалист',
        description: 'Доступ к техническим настройкам и диагностике.',
        icon: '🔧',
    }
};

const AccessLevelCard: React.FC<AccessLevelCardProps> = ({ level }) => {
    const { title, description, icon } = accessLevelData[level];

    return (
        <div className="access-level-card">
            <div className="access-level-icon">
                {icon}
            </div>
            <div className="access-level-content">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default AccessLevelCard;
