import axiosInstance from './axiosConfig';

interface LoginFormInputs {
    username: string;
    password: string;
}

interface AuthResponse {
    access: string;
    refresh: string;
}

export const loginUser = async (data: LoginFormInputs): Promise<AuthResponse> => {
    const response = await axiosInstance.post('/api/token/', {
        username: data.username,
        password: data.password,
    });
    return response.data;
};
