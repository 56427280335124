import axios from 'axios';
import config from '../config';

const axiosInstance = axios.create({
    baseURL: config.apiUrl,
    timeout: 10000,
});

// Добавляем заголовок с токеном в каждый запрос
axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // Устанавливаем Content-Type только если данные отправляются как JSON
    if (config.headers['Content-Type'] === 'application/json;charset=utf-8') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    } else {
        // Если данные формируются как FormData, удаляем Content-Type, чтобы браузер установил его автоматически
        delete config.headers['Content-Type'];
    }

    return config;
});

export default axiosInstance;
