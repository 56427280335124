import React, {useEffect, useState} from 'react';
import Carousel from '../organisms/home/carousel.home';
import {CarouselItem, NewsItem} from '../utils/service/home.service';
import CompanyInfo, {CompanyInfoProps} from "../molecules/home/companyInfoMolecule.home";
import NewsCard from "../molecules/home/newsCardMolecule.home";
import EditableBlock from "../utils/wrapper/editableBlock";
import CompanyInfoForm from "../organisms/utils/companyInfoForm";
import NewsBlock from "../molecules/home/newsBlockMolekule.home";

interface HomePageTemplateProps {
    carouselImages: CarouselItem[];
    companyInfo: CompanyInfoProps[];
    news: NewsItem[];
}

const HomePageTemplate: React.FC<HomePageTemplateProps> = ({carouselImages, companyInfo, news}) => {
    const [carouselData, setCarouselData] = useState<CarouselItem[]>(carouselImages);
    const [companyInfoData, setCompanyInfoData] = useState<CompanyInfoProps[]>(companyInfo);
    const [newsData, setNewsData] = useState<NewsItem[]>(news);
    const handleEdit = (section: string) => {
        console.log(`Edit ${section} clicked`);
        // todo тоже вынести
        // логикa редактирования
    };
    useEffect(() => {

    }, []);
    return (
        <div className="homepage">
            <div className="carousel-block">

                <Carousel
                    images={carouselImages}
                    autoPlay={true}
                    interval={5000}
                />

            </div>

            <div className="info-news">

                <div className="info-block">
                    {companyInfo.length > 0 && (
                        <CompanyInfo
                            data={companyInfo[0]}
                        />
                    )}
                </div>
                <NewsBlock news={news}/>

            </div>
        </div>
    );
};

export default HomePageTemplate;
