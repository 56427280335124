import axiosInstance from './axiosConfig';

export type Person = {
    id?: string;
    first_name: string;
    last_name: string;
    age: number;
    visits: number;
    progress: number;
    access: 'full' | 'first' | 'second';
};

export async function fetchData(
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: string,
    filters: { [key: string]: string }
): Promise<{
    data: Person[],
    totalPages: number
}> {
    const response = await axiosInstance.get('/api/info-table/', {
        params: {
            page,
            page_size: pageSize,
            ordering: sortOrder === 'desc' ? `-${sortBy}` : sortBy,
            ...filters
        }
    });
    return {
        data: response.data.results,
        totalPages: Math.ceil(response.data.count / pageSize),
    };
}

export async function deletePerson(id: string): Promise<void> {
    await axiosInstance.delete(`/api/info-table/${id}/`);
}

export async function updatePerson(person: Person): Promise<void> {
    if (person.id) {
        await axiosInstance.put(`/api/info-table/${person.id}/`, person);
    } else {
        await axiosInstance.post('/api/info-table/', person);
    }
}
