import React, {useState, useEffect, useRef} from 'react';
import CarouselItemMolecule from '../../molecules/home/carouselItemMolecule.home';
import CarouselIndicatorsMolecule from '../../molecules/home/carouselIndicatorMolecule.home';
import {CarouselItem} from '../../utils/service/home.service';

interface CarouselProps {
    images: CarouselItem[];
    autoPlay?: boolean;
    interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({images, autoPlay = true, interval = 3000}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [carouselImages, setCarouselImages] = useState<CarouselItem[]>(images);

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setCarouselImages(images);
        setCurrentIndex(0);
    }, [images]);

    useEffect(() => {
        if (!autoPlay || isTransitioning || carouselImages.length === 0) return;

        timerRef.current = setInterval(() => {
            goToNext();
        }, interval);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [autoPlay, interval, isTransitioning, carouselImages.length]);

    const goToNext = () => {
        if (isTransitioning || carouselImages.length === 0) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    };

    const goToPrev = () => {
        if (isTransitioning || carouselImages.length === 0) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
    };

    const handleTransitionEnd = () => {
        setIsTransitioning(false);
    };

    return (
        <div className='carousel'>
            <div
                className="carousel-inner"
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: 'transform 0.5s ease-in-out',
                }}
                onTransitionEnd={handleTransitionEnd}
            >
                {carouselImages.length > 0 ? (
                    carouselImages.map((image, index) => (
                        <CarouselItemMolecule
                            key={index}
                            image={image.image}
                        />
                    ))
                ) : (
                    <p>No images available</p>
                )}
            </div>
            <CarouselIndicatorsMolecule
                count={carouselImages.length}
                currentIndex={currentIndex}
                onIndicatorClick={(index) => setCurrentIndex(index)}
            />
        </div>
    );
};

export default Carousel;
