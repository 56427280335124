import React, {useEffect, useState} from 'react';
import {groupCheckAccess} from "./groupCheckAccess";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "./authContext";

interface GroupRestrictedRouteProps {
    children: JSX.Element;
}

const GroupRestrictedRoute: React.FC<GroupRestrictedRouteProps> = ({children}) => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
    const location = useLocation();

    useEffect(() => {
        const verifyAccess = async () => {
            if (user) {
                const hasAccess = await groupCheckAccess(location.pathname, user);
                setIsAuthorized(hasAccess);
            }
        };

        verifyAccess();
    }, [location.pathname, user]);

    if (isAuthorized === null) {
        return <div>Загрузка...</div>;
    }

    if (!isAuthorized) {
        return <div className='table-container'>Доступ запрещен</div>;
    }

    return children;
};

export default GroupRestrictedRoute;
