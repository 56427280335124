import React from 'react';
import '../../styles/header.css';

interface UserAvatarProps {
    src: string;
    alt: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({src, alt}) => (
    <div className="user-avatar">
        <img src={src} alt={alt}/>
    </div>
);

export default UserAvatar;
