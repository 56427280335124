import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {deleteAvatar, fetchProfile, updateProfile, uploadAvatar} from '../utils/service/profile.service';

import {useAuth} from '../utils/wrapper/authContext';
import ProfileTemplate from "../templates/profile.template";
import AccessLevelCardList from "../organisms/profile/accessLevelCardList.profile";
import Toast from "../atoms/toast";


export interface Profile {
    avatarSrc: string;
    user_first_name: string;
    user_last_name: string;
    user_email: string;
    user_groups: Array<'AUP' | 'ADMIN' | 'TECH'>;
}

const ProfilePage: React.FC = () => {
    const {updateUser} = useAuth();
    const [profile, setProfile] = useState<Profile>({
        avatarSrc: '',
        user_first_name: '',
        user_last_name: '',
        user_email: '',
        user_groups: []
    });
    const [toast, setToast] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

    useEffect(() => {
        document.title = 'ТН-Портал | Личный кабинет';
        const loadProfile = async () => {
            try {
                const profileData = await fetchProfile();
                setProfile(profileData);
            } catch (error) {
                console.error('Failed to fetch profile data:', error);
            }
        };
        loadProfile();
    }, []);

    const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                const newAvatarSrc = await uploadAvatar(file);
                setProfile((prevState) => ({
                    ...prevState,
                    avatarSrc: newAvatarSrc,
                }));

                updateUser({
                    exp: 0,
                    group: '',
                    first_name: profile.user_first_name,
                    last_name: profile.user_last_name,
                    username: '',
                    avatar: newAvatarSrc,
                });

                setToast({message: 'Аватар успешно обновлен', type: 'success'});
            } catch (error) {
                setToast({message: 'Не удалось обновить аватар', type: 'error'});
                if (axios.isAxiosError(error)) {
                    console.error('Axios error:', error.response?.data);
                } else {
                    console.error('Unknown error:', error);
                }
            }
        }
    };

    const handleProfileChange = (field: keyof Profile, value: string) => {
        setProfile((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleDeleteAvatar = async () => {
        try {
            await deleteAvatar();
            setProfile((prevState) => ({
                ...prevState,
                avatarSrc: '',
            }));
            setToast({message: 'Аватар успешно удален', type: 'success'});
        } catch (error) {
            setToast({message: 'Не удалось удалить аватар', type: 'error'});
            console.error('Failed to delete avatar:', error);
        }
    };

    const handleSaveChanges = async () => {
        try {
            await updateProfile(profile);
            localStorage.setItem('firstName', profile.user_first_name);
            localStorage.setItem('lastName', profile.user_last_name);
            localStorage.setItem('avatar', profile.avatarSrc);
            updateUser({
                exp: 0,
                group: '',
                first_name: profile.user_first_name,
                last_name: profile.user_last_name,
                username: '',
                avatar: profile.avatarSrc,
            });
            setToast({message: 'Профиль успешно обновлен', type: 'success'});
        } catch (error) {
            setToast({message: 'Не удалось обновить профиль', type: 'error'});
            console.error('Failed to update profile:', error);
        }
    };

    return (
        <div className="profileContainer">
            <ProfileTemplate
                avatarSrc={profile.avatarSrc}
                firstName={profile.user_first_name}
                lastName={profile.user_last_name}
                email={profile.user_email}
                userGroups={profile.user_groups}
                onAvatarChange={handleAvatarChange}
                onProfileChange={handleProfileChange}
                onDeleteAvatar={handleDeleteAvatar}
                onSaveChanges={handleSaveChanges}
            />
            {toast && (
                <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast(null)}
                />
            )}
        </div>
    );
};

export default ProfilePage;
