import React from 'react';
import ImageAtom from '../../atoms/home/imageAtom.home';

interface CarouselItemMoleculeProps {
    image: string;
}

const CarouselItemMolecule: React.FC<CarouselItemMoleculeProps> = ({ image }) => {
    return (
        <div className="carousel-item">
            <ImageAtom src={image} alt="Carousel Image" className="carousel-image" />
        </div>
    );
};

export default CarouselItemMolecule;
