import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import '../../styles/header.css';

interface NavLinkProps {
    to: string;
    label: string;
}

const NavLink: React.FC<NavLinkProps> = ({to, label}) => {
    const location = useLocation();
    const isProfileRoute = location.pathname !== '/home';

    const className = `nav-link ${isProfileRoute ? 'nav-link-profile' : ''}`;

    return (
        <Link to={to} className={className}>
            {label}
        </Link>
    );
};

export default NavLink;