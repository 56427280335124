import axiosInstance from './axiosConfig';

export interface Profile {
    avatarSrc: string;
    user_first_name: string;
    user_last_name: string;
    user_email: string;
    user_groups: Array<'AUP' | 'ADMIN' | 'TECH'>;
}

export const fetchProfile = async (): Promise<Profile> => {
    const response = await axiosInstance.get('/api/profile/');
    const data = response.data[0];
    let avatarSrc = data.avatar || '';

    // Проверяем, начинается ли avatarSrc с '/media/' и добавляем, если не начинается
    if (!avatarSrc.startsWith('/media/')) {
        avatarSrc = `/media/${avatarSrc}`;
    }

    return {
        avatarSrc: avatarSrc || '',
        user_first_name: data.user_first_name || '',
        user_last_name: data.user_last_name || '',
        user_email: data.user_email || '',
        user_groups: data.groups || []
    };
};

export const uploadAvatar = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('avatar', file);
    const response = await axiosInstance.post('/api/profile/upload-avatar/', formData);
    return response.data.avatar;
};

export const deleteAvatar = async (): Promise<void> => {
    await axiosInstance.delete('/api/profile/delete-avatar/');
};

export const updateProfile = async (profile: Profile): Promise<void> => {
    await axiosInstance.post('/api/profile/update-profile/', profile);
};
